import { v4 } from 'uuid';

import router from '@/vue-app/router';
import i18n from '@/vue-app/plugins/i18n';
import {
  requiredRule,
  emailFormat,
  RuleResponseType,
} from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import GetInternetStatusQuery
  from '@/modules/internet-status/application/queries/get-internet-status-query';
import RegisterUserServiceCommand
  from '@/modules/register/user/application/commands/register-user-command-service';
import { SignInCommand } from '@/modules/authentication/application/commands';
import SearchAgreementsAsyncQuery
  from '@/modules/agreements/application/queries/search-agreements-async-query';
import CreateOnboardingStepCommand
  from '@/modules/onboarding/status/application/commands/create-onboarding-step-command';
import OnBoardingVerifyEmailExistenceQuery
  from '@/modules/onboarding/email/application/queries/on-boarding-verify-email-existence-query';
import GetProductsQuery
  from '@/modules/onboarding/catalogs/product/application/queries/get-products-query';

// Domain
import { CustomerAgreementEntity }
  from '@/modules/onboarding/status/domain/entities/customer-agreement-entity';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import DatetimeValue from '@/modules/shared/domain/value-objects/datetime-value';
import PasswordValidator from '@/modules/authentication/domain/services/validate/password-validator';
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator,
  noConsecutiveCharactersValidator,
  noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/user/domain/services/password-validators';

export default class SignUpViewModel {
  @Inject(TYPES.GET_INTERNET_STATUS_QUERY)
  private readonly get_internet_status_query!: GetInternetStatusQuery;

  @Inject(TYPES.REGISTER_USER_COMMAND_SERVICE)
  private readonly register_user_command!: RegisterUserServiceCommand;

  @Inject(TYPES.SIGN_IN_COMMAND)
  private readonly sign_in_command!: SignInCommand;

  @Inject(TYPES.SEARCH_AGREEMENTS_ASYNC_QUERY)
  private readonly searchAgreementsAsyncQuery!: SearchAgreementsAsyncQuery;

  @Inject(TYPES.CREATE_ONBOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnboardingStepCommand;

  @Inject(TYPES.ON_BOARDING_VERIFY_EMAIL_EXISTENCE_QUERY)
  private readonly on_boarding_verify_email_existence_query!: OnBoardingVerifyEmailExistenceQuery;

  @Inject(TYPES.GET_PRODUCTS_QUERY)
  private readonly get_products_query!: GetProductsQuery;

  @Inject(TYPES.DATETIME_VALUE)
  private datetime_value!: DatetimeValue;

  private get internet_status() {
    return this.get_internet_status_query.execute();
  }

  private static get group_id() {
    const group_id_from_env = process.env.VUE_APP_USER_GROUP_ID;
    const default_group_id = '6d48b0d7-f570-4689-a518-1ab1c6bb17e7';

    return group_id_from_env || default_group_id;
  }

  private readonly step_ids = {
    sign_up: v4(),
    verify_nip: v4(),
  };

  get login_path() {
    return process.env.VUE_APP_COLUMBUS_WEB_LOGIN_URL;
  }

  inputs = {
    email: '',
    password: '',
    confirm_password: '',
    accept_terms_and_conditions: false,
    accept_notice_of_privacy: false,
    accept_digital_media: false,
  };

  inputs_config = {
    email: {
      rules: [requiredRule, emailFormat],
    },
    password: {
      type: 'password',
      rules: [
        requiredRule,
        (value: string) => (
          PasswordValidator.validate(value, [this.inputs.email.split('@')[0]])
          || i18n.t('shared.form-rules.password_format').toString()
        ),
      ],
    },
    confirm_password: {
      type: 'password',
      rules: [
        (value: string): RuleResponseType => value === this.inputs.password || 'Las contraseñas no coinciden.',
      ],
    },
  };

  user_agreements: Array<CustomerAgreementEntity> = [
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
    {
      description: '',
      id: v4(),
      agreement_type_id: '',
      accepted_on: null,
      was_accepted: false,
    },
  ];

  is_open_modal = {
    terms_and_conditions: false,
    notice_of_privacy: false,
    digital_media: false,
    existent_email: false,
  };

  modal_error = {
    title: 'Error al registrarse',
    message: 'Verifica tu información e inténtalo nuevamente',
    is_open: false,
  };

  is_valid_form = false;

  is_loading = false;

  view!: any;

  default_product_ids: Array<number> | null = null;

  get is_password_min_length_valid() {
    return minLengthValidator(this.inputs.password);
  }

  get password_contains_upper_case_one_lower_case_and_one_digit() {
    return containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.password);
  }

  get password_contains_columbus_word_or_email() {
    const email_id = this.inputs.email.split('@')[0];
    return blacklistValidator(this.inputs.password, [email_id]);
  }

  get password_has_no_consecutive_characters() {
    return noConsecutiveCharactersValidator(this.inputs.password);
  }

  get password_has_no_consecutive_repeated_characters() {
    return noConsecutiveRepeatedCharactersValidator(this.inputs.password);
  }

  get can_continue() {
    return (
      this.is_valid_form
      && this.inputs.accept_terms_and_conditions
      && this.inputs.accept_notice_of_privacy
      && this.inputs.accept_digital_media
      && !this.is_loading
      && this.internet_status
    );
  }

  public constructor(view: any) {
    this.view = view;
  }

  acceptTermsAndConditions = (accept: boolean) => {
    this.inputs.accept_terms_and_conditions = accept;
    this.user_agreements[0].was_accepted = accept;
    this.user_agreements[0].accepted_on = this.datetime_value.create();
  }

  acceptNoticeOfPrivacy = (accept: boolean) => {
    this.inputs.accept_notice_of_privacy = accept;
    this.user_agreements[1].was_accepted = accept;
    this.user_agreements[1].accepted_on = this.datetime_value.create();
  }

  acceptDigitalMedia = (accept: boolean) => {
    this.inputs.accept_digital_media = accept;
    this.user_agreements[2].was_accepted = accept;
    this.user_agreements[2].accepted_on = this.datetime_value.create();
  }

  register = async () => {
    this.is_loading = true;

    try {
      await this.view.$recaptchaLoaded();

      const user_id = v4();
      const token = await this.view.$recaptcha('login');

      await this.register_user_command
        .execute({
          group_id: SignUpViewModel.group_id,
          id: user_id,
          username: this.inputs.email,
          password: this.inputs.password,
          password_confirmation: this.inputs.confirm_password,
          token,
        });
      await this.sign_in_command.execute({
        username: this.inputs.email,
        password: this.inputs.password,
      });
      await this.loadProducts();
      await this.fillAgreementsInformation();
      await this.create_on_boarding_step_command.execute({
        id: this.step_ids.sign_up,
        current_step: 'sign_up',
        payload: {
          agreements: this.user_agreements,
          default_promoter_employee_id: process.env.VUE_APP_DEFAULT_PROMOTER_EMPLOYEE_ID,
          default_referrer_employee_id: process.env.VUE_APP_DEFAULT_REFERRER_EMPLOYEE_ID,
          default_endorsements_ids: this.default_product_ids,
        },
      });
      await this.create_on_boarding_step_command.execute({
        id: this.step_ids.verify_nip,
        current_step: 'verify_nip',
        payload: {
          geolocation: {
            reference: null,
            coordinates: null,
          },
        },
      });
      sessionStorage.setItem('user_id', user_id);
      this.view.$recaptchaInstance.hideBadge();
      await router.push('/onboarding');
    } catch {
      await this.showError();
    } finally {
      this.is_loading = false;
    }
  }

  private async fillAgreementsInformation() {
    const agreements = await this.searchAgreementsAsyncQuery.execute();
    const terms_conditions_agreement = agreements.find(
      (item) => item.name === 'terms_and_conditions_columbus',
    );
    const notice_privacy = agreements.find(
      (item) => item.name === 'notice_of_privacy_columbus',
    );
    const digital_consent = agreements.find(
      (item) => item.name === 'digital_media_consent_columbus',
    );

    if (terms_conditions_agreement && notice_privacy && digital_consent) {
      this.user_agreements[0].agreement_type_id = terms_conditions_agreement.id;
      this.user_agreements[0].description = terms_conditions_agreement.description;
      this.user_agreements[1].agreement_type_id = notice_privacy.id;
      this.user_agreements[1].description = notice_privacy.description;
      this.user_agreements[2].agreement_type_id = digital_consent.id;
      this.user_agreements[2].description = digital_consent.description;
    } else {
      throw new Error('Agreement wasn\'t found');
    }
  }

  loadProducts = async () => {
    try {
      const products = await this.get_products_query.execute();
      this.default_product_ids = products.map((product) => product.product_id);
    } catch {
      this.default_product_ids = null;
    }
  }

  private async showError() {
    try {
      const email_exists = await this.on_boarding_verify_email_existence_query.execute(
        this.inputs.email,
      );

      if (email_exists) {
        this.is_open_modal.existent_email = true;
      } else {
        this.modal_error.is_open = true;
      }
    } catch {
      this.modal_error.is_open = true;
    }
  }
}
